import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { makeStyles, Grid, Container, Snackbar, TextField, withStyles, Button, useMediaQuery, CircularProgress } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm, Controller } from 'react-hook-form'
import Master from '../components/Master'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const defaultValues = {
  name: "",
  email: "",
  whatsApp: "",
}

const company = 'Renata Scarpelli - Design para Festas'

export default function Home() {

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const snackbar = (message, severity) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const methods = useForm()
  const { handleSubmit, control, reset } = methods
  const onSubmit = data => {
    setIsLoading(true);
    console.log('Name', data.name);
    console.log('Email', data.email);
    console.log('WhatsApp', data.whatsApp);
    fetch(`https://us-central1-renata-scarpelli.cloudfunctions.net/api/landingpage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: data.name ? data.name : "",
        email: data.email ? data.email : "",
        whatsApp: data.whatsApp ? data.whatsApp : "",
      })
    })
      .then(res => {
        return res.json()
      })
      .then(resData => {
        console.log('resData', resData);
        if (resData.errors) {
          if (resData.errors[0].status === 422) {
            if (resData.errors[0].data[0].message === 'Name cannot be null.') {
              snackbar('Preencha o campo Nome.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Email is invalid.') {
              snackbar('Preencha o campo Email com um email válido.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'Email cannot be null.') {
              snackbar('Preencha o campo Email.', 'error')
            }
            else if (resData.errors[0].data[0].message === 'WhatsApp cannot be null.') {
              snackbar('Preencha o campo WhatsApp.', 'error')
            }
            else {
              snackbar(resData.errors[0].data[0].message, 'error')
            }
          }
          setIsLoading(false)
        }
        else {
          setIsLoading(false)
          snackbar('Formulário enviado com sucesso!', 'success')
          reset({ defaultValues })
          navigate(`/muitoobrigado`)
        }
      })
      .catch(err => {
        setIsLoading(false)
        console.log('err', err);
        snackbar('Erro ao enviar formulário, verifique todos os campos e tente novamente.', 'error')
      })
  }

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: `#000`
      },
      '& label': {
        fontFamily: `Open Sans`,
        fontSize: `18px`
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `#222`
      },
      '& .MuiInputBase-input': {
        fontFamily: `Open Sans`,
        fontSize: `18px`
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `#222`,
        },
        '&:hover fieldset': {
          borderColor: `#222`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `#222`,
        }
      },
    },
  })(TextField)

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(0, 3, 6),
    },
    imageOnMediaQuery: {
      width: '100%'
    },
    button: {
      marginTop: '30px',
      padding: '10px',
      backgroundColor: `#F12E46`,
      color: `#FFF`,
      textDecoration: 'none',
      borderRadius: '5px',
      width: '100%',
      fontFamily: `Open Sans`,
      fontSize: `20px`,
      '&:hover': {
        backgroundColor: `#006600`,
      },
    },
    featured: {
      fontSize: '28px',
      textAlign: 'center'
    },
    width: {
      width: '100%'
    },
    circular: {
      marginTop: '10px'
    }
  }));

  const classes = useStyles();

  // const imageMediaQuery = useMediaQuery('(min-width:373px)');
  const image = <img src={`/galinha-pintadinha.jpg`} title={company} alt={company} className={classes.width} />
  // let image = <img src={`https://picsum.photos/200`} title={company} alt={company} className={classes.imageOnMediaQuery} />
  // if (!imageMediaQuery) {
  //   image = <img src={`https://picsum.photos/200`} title={company} alt={company} />
  // }

  let progress
  if (isLoading) {
    progress = (
      <div className={classes.circular}>
        <CircularProgress />
      </div>
    )
  } else {
    progress = null
  }
  let button
  if (!isLoading) {
    button =
      <Button className={classes.button} size="medium" variant="contained" color="primary" type="submit" disabled={isLoading}>
        Receber arte gratuita
      </Button>
  } else {
    button = null
  }
  return (
    <Master>
      <GatsbySeo
        title={company}
        description={company}
        openGraph={{
          title: `${company}`,
          description: `${company}`,
          images: [
            {
              url: '/renata-scarpelli_512.png',
              width: 512,
              height: 512,
              alt: `${company}`
            }
          ],
          site_name: `${company}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className={classes.featured}>
              Receba <strong>GRÁTIS</strong> uma arte em alta definição de painel redondo Galinha Pintadinha
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>É bem simples: Basta preencher as informações abaixo  e enviaremos para você por email.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller as={CssTextField} name="name" control={control} label="Nome completo" className={classes.width} />
              <Controller as={CssTextField} name="email" control={control} label="Email" className={classes.width} />
              <Controller as={CssTextField} name="whatsApp" control={control} label="WhatsApp [exemplo: (31) 99999-9999]" className={classes.width} />
              {button}
              {progress}
            </form>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          <Grid item xs={12} sm={6}>
            {image}
          </Grid>
        </Grid>
      </Container >
      <Container component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className={classes.featured}>
              TEMOS ARTES COM PREÇOS ESPECIAIS PARA GRÁFICAS
            </p>
          </Grid>
        </Grid>
      </Container >
    </Master >
  )
}
